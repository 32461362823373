<template>
  <main id="charge">
    <Loading v-if="isLoading" />
    <template v-else>
      <section class="section-top">
        <ConnectorSpec :connector="connector" />
      </section>
      <FeeInfoCard :unitPrice="unitPrice" />
      <section class="section-main">
        <div class="section-main__title">
          <h1>享受超高速充電</h1>
          <h1>方便、可靠而且經濟實惠</h1>
        </div>
        <div class="section-main__action">
          <button
            class="button is-fullwidth is-fill is-rounded"
            type="button"
            :disabled="isProcessing"
            @click="handleClick()"
          >
            下一步
          </button>
        </div>
      </section>
      <div class="modal" :class="{ active: isModalOpen }">
        <PriceAnnouncementModal
          @price-adjust="handleCancel"
          :current-price="currentPrice"
        />
      </div>
    </template>
    <section id="mask" v-if="!isCloseConfirmCard">
      <div class="confirmCard">
        <img src="../assets/photo/confirmCardBg.png" alt="" />
        <div class="confirmCard-Body">
          <div class="logo">
            <inline-svg
              width="60"
              height="60"
              :src="require('../assets/icon/svg/logo.svg')"
            />
          </div>
          <div class="titleBox">
            <div class="title">下載 U-POWER App</div>
            <div class="subtitle">獲得最佳充電體驗及會員優惠</div>
          </div>
        </div>
        <div class="confirmCard-Footer">
          <button @click="isCloseConfirmCard = true">下次再說</button>
          <button @click="redirectToAppPage">立即前往下載</button>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import FeeInfoCard from "../components/Connector/FeeInfoCard.vue";
import Loading from "../components/Loading.vue";
import ConnectorSpec from "../components/Connector/ConnectorSpec.vue";
import PriceAnnouncementModal from "../components/Connector/PriceAnnouncementModal.vue";
import chargingAPI from "../apis/charging";
import connectorAPI from "../apis/connector";

export default {
  name: "ConnectorApp",
  data() {
    return {
      isCloseConfirmCard: false,
      isProcessing: false,
      userAgent: "",
      connectorStatus: {
        Available: 1,
        Unavailable: 2,
        Preparing: 3,
        Charging: 4,
        SuspendedEVSE: 5,
        SuspendedEV: 6,
        Finishing: 7,
        Faulted: 8,
        Remove: 9,
        Reserved: 10,
        Offline: 11,
        UnKnow: 12,
      },
      unitPrice: {
        pricingPlanId: "",
        unitPrice: 0,
      },
      currentPrice: {
        pricingPlanId: "",
        unitPrice: 0,
      },
      currentConnectorStatus: {
        connectorId: "",
        status: 0,
      },
      isLoading: false,
      isModalOpen: false,
      connector: {
        connectorId: "",
        stationId: "",
        boxId: "",
        connector: "",
        current: 0,
        position: 1,
        connectorType: {
          connectorTypeId: 0,
          connectorType: "",
        },
        flag: 1,
        status: 1,
        available: 1,
      },
    };
  },
  components: { FeeInfoCard, Loading, ConnectorSpec, PriceAnnouncementModal },
  async mounted() {
    this.scrollToTop();
    const { chargebox, connectorno, connectorId, stationId } =
      this.$route.query;
    if (
      connectorId &&
      stationId &&
      connectorId.length === 36 &&
      stationId.length === 36
    ) {
      this.checkConnectorState(connectorId);
    } else if (
      chargebox &&
      connectorno &&
      chargebox.length === 25 &&
      connectorno.length === 1
    ) {
      this.fetchConnectorByBoxCodeConnectorNo({
        chargebox: chargebox,
        connectorno: connectorno,
      });
    } else {
      this.$router.push("/search");
    }

    this.checkDeviceOs();
  },
  methods: {
    redirectToAppPage() {
      const googlePlayUrl =
        "https://play.google.com/store/apps/details?id=com.u_power";
      const appStoreUrl = "https://apps.apple.com/tw/app/u-power/id1612379206";

      if (this.userAgent === "Android") {
        window.location.href = googlePlayUrl;
        return;
      }
      if (this.userAgent === "IOS") {
        window.location = appStoreUrl;
        return;
      }
      if (this.userAgent === "WindowsDesktop") {
        window.open(
          googlePlayUrl,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes"
        );
        return;
      }
      if (this.userAgent === "MacDesktop") {
        window.open(
          appStoreUrl,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes"
        );
        return;
      }
    },
    checkDeviceOs() {
      let userAgent = navigator.userAgent;

      if (userAgent.match(/Android/i)) {
        if (userAgent.match(/Chrome/i)) {
          // Android + Chrome
          setTimeout(() => {
            if (!document.webkitHidden) {
              this.userAgent = "Android";
              return;
            }
          }, 1000);
        } else {
          // Android + 非 Chrome
          this.userAgent = "Android";
          return;
        }
      } else if (userAgent.match(/(iPhone|iPad|iPod)/i)) {
        setTimeout(() => {
          this.userAgent = "IOS";
          return;
        }, 25);
      } else if (userAgent.match(/(Macintosh|Mac)/i)) {
        // Mac Desktop Device
        setTimeout(() => {
          this.userAgent = "MacDesktop";
          return;
        }, 25);
      } else if (userAgent.match(/(Windows)/i)) {
        // Windows Desktop Device
        setTimeout(() => {
          this.userAgent = "WindowsDesktop";
          return;
        }, 25);
      } else {
        //其他
        let loadDateTime = new Date();
        setTimeout(() => {
          let timeOutDateTime;
          timeOutDateTime = new Date();
          if (timeOutDateTime - loadDateTime < 5000) {
            this.userAgent = "Android";
            return;
          }
        }, 1000);
      }
    },
    async checkConnectorState(connectorid) {
      try {
        this.isLoading = true;
        const { connectorId, stationId } = this.$route.query;
        const { data, status } = await connectorAPI.connector.getStatus({
          connectorid,
        });
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        this.currentConnectorStatus = data;
        if (
          this.currentConnectorStatus.status ===
            this.connectorStatus.Unavailable ||
          this.currentConnectorStatus.status ===
            this.connectorStatus.Charging ||
          this.currentConnectorStatus.status ===
            this.connectorStatus.SuspendedEVSE ||
          this.currentConnectorStatus.status ===
            this.connectorStatus.SuspendedEV ||
          this.currentConnectorStatus.status ===
            this.connectorStatus.Finishing ||
          this.currentConnectorStatus.status === this.connectorStatus.Faulted ||
          this.currentConnectorStatus.status === this.connectorStatus.Remove ||
          this.currentConnectorStatus.status ===
            this.connectorStatus.Reserved ||
          this.currentConnectorStatus.status === this.connectorStatus.Offline ||
          this.currentConnectorStatus.status === this.connectorStatus.UnKnow
        ) {
          this.$router.push({
            name: "BeingUsedHint",
            params: {
              connectorId: connectorId
                ? connectorId
                : this.connector.connectorId,
              stationId: stationId ? stationId : this.connector.stationId,
            },
          });
        } else {
          const { connectorId, stationId } = this.$route.query;
          if (connectorId) {
            this.fetchConnector(connectorId);
          }
          this.$store.dispatch("fetchStation", {
            stationid: stationId ? stationId : this.connector.stationId,
          });
          const payload = stationId ? stationId : this.connector.stationId;
          this.getUnitPrice(payload);
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        console.error(error.message);
      }
    },
    async fetchConnectorByBoxCodeConnectorNo(chargebox, connectorno) {
      try {
        this.isLoading = true;
        const { data, status } =
          await connectorAPI.connector.getByBoxCodeConnectorNo(
            chargebox,
            connectorno
          );
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        if (status === 204) {
          this.$router.push({
            path: "/404",
          });
        }
        this.connector = data;
        this.checkConnectorState(this.connector.connectorId);
      } catch (error) {
        this.isLoading = false;
        console.error(error.message);
      }
    },
    async getUnitPrice(stationid) {
      try {
        const { data, status } = await chargingAPI.charging.getUnitPrice({
          stationid,
        });
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        this.unitPrice = data;
      } catch (error) {
        console.error(error.message);
        this.isLoading = false;
      }
    },
    handleCancel() {
      this.unitPrice = this.currentPrice;
      this.handleModal();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    async fetchConnector(connectorid) {
      try {
        const { data, status } = await connectorAPI.connector.getByConnectorId({
          connectorid: connectorid,
        });
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        this.connector = data;
      } catch (error) {
        console.error(error.message);
      }
    },
    handleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    async handleClick() {
      try {
        const { data, status } = await chargingAPI.charging.getUnitPrice({
          stationid: this.connector.stationId,
        });
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        this.currentPrice = data;
        if (this.currentPrice.unitPrice !== this.unitPrice.unitPrice) {
          this.handleModal();
        } else {
          this.$router.push({
            name: "PlugHint",
            params: {
              connectorId: this.connector.connectorId,
              stationId: this.connector.stationId,
            },
          });
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    async handleClickBack() {
      try {
        this.$router.push({
          name: "ConnectorSearch",
        });
      } catch (error) {
        console.error(error.message);
      }
    },
  },
  watch: {
    userAgent() {
      if (this.userAgent !== "") {
        this.redirectToAppPage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#mask {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  // background: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  .confirmCard {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 90vw;
    max-width: 350px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: auto;
      height: 75%;
      z-index: -10;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    .confirmCard-Body {
      padding: 2rem 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;
      .logo {
        width: 60px;
        height: 60px;
        margin-right: 1.2rem;
        border-radius: 12px;
        overflow: hidden;
      }
      .titleBox {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex-grow: 1;
        color: $white-bis;
        .title {
          margin-bottom: 0.75rem;
          font-size: $size-5;
          font-weight: $weight-bold;
        }
        .subtitle {
          font-size: $size-7;
        }
        @media screen and (min-width: 380px) {
          .title {
            font-size: 23px;
          }
          .subtitle {
            font-size: 16px;
          }
        }
        @media screen and (max-width: 350px) {
          .title,
          .subtitle {
            font-size: 12px;
          }
        }
      }
    }
    .confirmCard-Footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        padding: 1.25rem 0;
        display: block;
        width: 50%;
        background: none;
        border: none;
        box-shadow: inset 0 0 0 1px $primary;
        font-size: $size-6;
        &:nth-child(1) {
          border-bottom-left-radius: 12px;
          background: $white;
          color: $primary;
        }
        &:nth-child(2) {
          border-bottom-right-radius: 12px;
          background: $primary;
          color: $white-bis;
        }
      }
      @media screen and (max-width: 350px) {
        button {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
