var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-dialog is-top is-rounded"},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('p',[_vm._v(" 目前充電費率調整為 "),_c('strong',[_vm._v(_vm._s(_vm.currentPrice.unitPrice))]),_vm._v(" 每千瓦小時/元。 ")])]),_c('div',{staticClass:"modal-footer"},[_c('router-link',{staticClass:"button is-fullwidth mb-2 is-fill",attrs:{"to":{
          name: 'PlugHint',
          params: {
            connectorId: this.$route.params.connectorId,
            stationId: this.$route.params.stationId,
          },
        }}},[_vm._v(" 繼續前往充電 ")]),_c('button',{staticClass:"button is-fullwidth is-normal",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleCancel()}}},[_vm._v(" 返回 ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h1',{staticClass:"modal-header__title"},[_vm._v("充電費率變動")])])
}]

export { render, staticRenderFns }