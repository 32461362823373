<template>
  <div class="spec-box">
    <div class="spec-item__icon">
      <inline-svg
        v-if="connector.connectorType.connectorTypeId === connectorType.CCS1"
        :src="require('../../assets/icon/svg/CCS1.svg')"
      />
      <inline-svg
        v-if="connector.connectorType.connectorTypeId === connectorType.CCS2"
        :src="require('../../assets/icon/svg/CCS2.svg')"
      />
    </div>
    <div class="spec-item__text">
      <p class="spec-item__text--adj">
        {{ connector.current > 300 ? "超高速" : "高速" }}充電槍：{{
          connector.connector
        }}
      </p>

      <p class="spec-item__text--spec">
        {{ connector.connectorType.connectorType }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "ConnectorSpec.vue",
  props: {
    connector: { type: Object, require: true },
  },
  data() {
    return {
      connectorType: {
        J1772: 1,
        IEC62196: 2,
        GBTAC: 3,
        CCS2AC: 4,
        TypeE: 5,
        ChAdeMo: 6,
        CCS1: 7,
        CCS2: 8,
        GBTDC: 9,
      },
    };
  },
};
</script>
